.formTitle {
  margin-top: 0;
  margin-bottom: 2rem !important;
  text-align: center;
}

.formBackground {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  margin-bottom: 1rem;
  padding: 1rem;
  padding-top: 1.5rem;
}