.main {
  margin: auto;
  width: 92%;
  height: 100%;
  max-width: 45rem;
  padding-top: 1.5rem;
  padding-left: calc(100vw - 100%); /* prevent scrollbar from repositioning page */
}

.container {
  height: calc(100% - 60px);
  position: absolute;
  width: 100%;
  overflow-y: auto;
}
