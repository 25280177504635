.cardReady {
  border-left-width: 5px;
  border-left-color: #00800077;
  background: linear-gradient(to right, #00800035 1%, #00800025 3%, #00800015 8%, transparent 40%);
}

.card, .cardReady {
  /*box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);*/
  margin-bottom: 0;
  padding: 1rem;
  border-radius: 0px;
  border-top: 1px solid #e2e8f0;
  border-bottom: none;
  border-right: none;
  border-left: none;
  transition: all 0.1s ease;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075); /* shadow-sm */
}

.card:hover, .cardReady:hover {
  background-color: rgba(0, 140, 255, 0.09);
  /*transform: scale(1.04);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);*/
}

.page {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}