.accordion-button:focus {
  box-shadow: none !important;
}

.btn:focus {
  outline: none;
  box-shadow: none !important;
}

.btn {
  border-radius: 0 !important;
}

.btn-custom {
  width: 100px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 500;
  color: #000;
  background-color: #badbcbc2;
  border: none;
  border-radius: 0px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.danger {
  width: 80px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  background-color: rgb(213, 54, 54);
}

.btn-custom:hover:enabled {
  background-color: #5EAB87;
  box-shadow: 0px 15px 20px rgba(70, 159, 118, 0.4);
  color: #fff;
  transform: translateY(-5px);
}

.btn-custom:disabled {
  opacity: 0.6;
}

.wide {
  width: 120px;
}

.nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

html {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  scrollbar-width: none;
}

#root, #root>div {
  background-color: rgb(245, 245, 245);
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}